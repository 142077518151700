<template>
  <button
    :class="[themeStyles, sizes[size]]"
    class="transition-ease-in-out focus:outline-none focus-visible:outline-none hover:opacity-70 rounded-xl relative"
    type="button"
  >
    <span :class="isLoading && 'opacity-0'">
      <slot />
    </span>
    <div v-if="isLoading">
      <Loading
        :active="isLoading"
        :can-cancel="false"
        :color="loaderColor"
        :height="25"
        :is-full-page="false"
        :opacity="0"
        :width="25"
      />
    </div>
  </button>
</template>

<script>
import Loading from "vue-loading-overlay";

export default {
  name: "CoreButtonBase",
  components: {
    Loading,
  },
  props: {
    theme: {
      type: String,
      default: "transparent",
    },
    size: {
      default: "normal",
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    loaderColor: {
      type: String,
      default: "#000",
    },
  },
  data() {
    return {
      themes: {
        shadow: "bg-transparent shadow-transparent-button",
        link: "bg-transparent text-fiolet",
        transparent: "bg-transparent",
        primary: "bg-blue text-white hover:opacity-70",
        primaryLight: "bg-yellow text-blue text-white hover:opacity-70",
        outlinePrimaryLight:
          "bg-yellow text-blue border-2 border-tags-alerts border-opacity-20 hover:opacity-70",
        outlineSecondary: "outline-secondary",
        disabled:
          "bg-gray text-white bg-opacity-30 shadow-drop pointer-events-none",
        secondary: "secondary",
        white:
          "bg-white text-main hover:opacity-70 font-medium border-2 border-white",
        danger: "danger",
        success: "success",
      },
      sizes: {
        small: "px-2 py-0.5",
        normal: "px-4 py-2.5",
        custom: "",
        paddingBlock: "px-[60px] py-[13px]",
      },
    };
  },
  computed: {
    themeStyles() {
      return this.disabled ? this.themes.disabled : this.themes[this.theme];
    },
  },
};
</script>

<style lang="scss" scoped>
.outline-secondary {
  @apply bg-transparent text-main border border-main hover:opacity-70 font-normal text-base;
}

.secondary {
  @apply bg-main border border-main text-white hover:opacity-70 text-base shadow-transparent-button;
}

.danger {
  @apply bg-red border border-red text-white hover:opacity-70 text-base shadow-transparent-button;
}

.success {
  @apply bg-badge-green-primary border border-badge-green-primary text-white hover:opacity-70 text-base shadow-transparent-button;
}
</style>

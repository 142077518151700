import http from "@/utils/axios";

export default {
  namespaced: true,
  state: {
    discountCodes: [],
    types: [],
    generateCodeTypes: [],
    pagination: {
      currentPage: 1,
      total: 1,
    },
  },
  getters: {
    getDiscountCodes: (state) => state.discountCodes,
    getTypes: (state) => state.types,
    getGenerateCodeTypes: (state) => state.generateCodeTypes,
    getPagination: (state) => state.pagination,
  },
  mutations: {
    setDiscountCodes(state, payload) {
      state.discountCodes = payload.data;
      state.pagination.currentPage = payload.meta.current_page;
      state.pagination.total = payload.meta.last_page;
    },
    toggleActivePromotion(state, payload) {
      const discountCode = state.discountCodes.find(
        (item) => item.id === payload
      );
      if (discountCode) {
        discountCode["is_active"] = !discountCode["is_active"];
      }
    },
    toggleActiveSlipOfNotepaper(state, payload) {
      const discountCode = state.discountCodes.find(
        (item) => item.id === payload
      );
      if (discountCode) {
        discountCode["has_slip_of_notepaper"] =
          !discountCode["has_slip_of_notepaper"];
      }
    },
    toggleActiveAutoApply(state, payload) {
      const discountCode = state.discountCodes.find(
        (item) => item.id === payload
      );
      if (discountCode) {
        discountCode["apply_code_automatically"] =
          !discountCode["apply_code_automatically"];
      }
    },
    setTypes(state, payload) {
      state.types = payload;
    },
    setGenerateCodeTypes(state, payload) {
      state.generateCodeTypes = payload;
    },
    setCurrentPage(state, currentPage) {
      state.pagination.currentPage = currentPage;
    },
  },
  actions: {
    async fetchDiscountCodes({ commit, state }) {
      const { data } = await http.get(`admin/discount-code`, {
        params: {
          page: state.pagination.currentPage,
        },
      });
      commit("setDiscountCodes", data.discountCodes);
    },
    async fetchDiscountCodeTypes({ commit }) {
      const { data } = await http.get(`admin/discount-code/create`);
      commit("setTypes", data.types);
      commit("setGenerateCodeTypes", data.generateCodeTypes);
    },
    async toggleActivePromotion({ commit }, payload) {
      commit("toggleActivePromotion", payload);
      await http.put(`admin/discount-code/toggle-active/${payload}`);
    },
    async toggleActiveSlipOfNotepaper({ commit }, payload) {
      commit("toggleActiveSlipOfNotepaper", payload);
      await http.put(
        `admin/discount-code/toggle-active-slip-of-notepaper/${payload}`
      );
    },
    async toggleActiveAutoApply({ commit }, payload) {
      commit("toggleActiveAutoApply", payload);
      await http.put(`admin/discount-code/toggle-active-auto-apply/${payload}`);
    },
    async downloadCodes({ commit }, url) {
      commit("setLoadingRaports", true);
      return await http({
        url: url,
        method: "GET",
        responseType: "blob",
      })
        .then((response) => {
          commit("clearErrors");
          const filename =
            response.headers["content-disposition"].split("filename=")[1];
          const url = window.URL.createObjectURL(new Blob([response.data]));
          let link = document.createElement("a");
          link.href = url;
          link.download = filename;
          document.body.appendChild(link);
          link.click();
          setTimeout(() => {
            URL.revokeObjectURL(url);
            link.remove();
          }, 1000);
          commit("setLoadingRaports", false);
        })
        .catch(({ response }) => {
          commit("setLoadingRaports", false);
          if (response && response.status === 422) {
            commit("setErrors", response.data.errors);
          }
        });
    },
  },
};

import http from "@/utils/axios";
import {
  deleteNestedKeyAndClearEmpty,
  getValueByPath,
} from "@/utils/objectManipultaion";
import { notify } from "@kyvg/vue3-notification";
import router from "@/router";

export default {
  namespaced: true,
  state: {
    planId: null,
    patient: null,
    selectedPlan: null,
    doctorDescription: null,
    testResults: null,
    healthMetrics: null,
    generalSurvey: null,
    weightLossSurvey: null,
    weekNumber: 1,
    weekPlans: [],
    shoppingList: {},
    nutritionistNote: "",
    trainingNote: "",
    isLoading: true,
    isUpdatingShoppingList: false,
    shouldUpdateShoppingList: false,
    errors: {},
  },
  getters: {
    getParentData: (state) => state.patient,
    getSelectedPlan: (state) => state.selectedPlan,
    getDoctorDescription: (state) => state.doctorDescription,
    getTestResults: (state) => state.testResults,
    getHealthMetrics: (state) => state.healthMetrics,
    getGeneralSurvey: (state) => state.generalSurvey,
    getWeightLossSurvey: (state) => state.weightLossSurvey,
    getWeekPlans: (state) => state.weekPlans,
    getNutritionistNote: (state) => state.nutritionistNote,
    getTrainingNote: (state) => state.trainingNote,
    getShoppingList: (state) => state.shoppingList,
    getWeekNumber: (state) => state.weekNumber,
    isLoading: (state) => state.isLoading,
    isUpdatingShoppingList: (state) => state.isUpdatingShoppingList,
    shouldUpdateShoppingList: (state) => state.shouldUpdateShoppingList,
    getErrorsFor: (state) => (errorKey) => {
      return getValueByPath(state.errors, errorKey);
    },
    getErrors: (state) => state.errors,
  },
  mutations: {
    setPlanId(state, id) {
      state.planId = id;
    },
    setPatientData(state, patient) {
      state.patient = patient;
    },
    setSelectedPlan(state, selectedPlan) {
      state.selectedPlan = selectedPlan;
    },
    setDoctorDescription(state, doctorDescription) {
      state.doctorDescription = doctorDescription;
    },
    setTestResults(state, testResults) {
      state.testResults = testResults;
    },
    setHealthMetrics(state, healthMetrics) {
      state.healthMetrics = healthMetrics;
    },
    setGeneralSurvey(state, generalSurvey) {
      state.generalSurvey = generalSurvey;
    },
    setWeightLossSurvey(state, weightLossSurvey) {
      state.weightLossSurvey = weightLossSurvey;
    },
    setWeekPlans(state, weekPlans) {
      state.weekPlans = weekPlans;
    },
    setShoppingList(state, shoppingList) {
      state.shoppingList = shoppingList;
    },
    setWeekNumber(state, weekNumber) {
      state.weekNumber = weekNumber;
    },
    updateWeekPlanDayMeal(state, { dayIndex, mealIndex, data }) {
      Object.assign(state.weekPlans[dayIndex].meals[mealIndex], data);

      state.weekPlans[dayIndex].total_calories = state.weekPlans[
        dayIndex
      ].meals.reduce((prevSum, { total_calories }) => {
        return prevSum + total_calories * 1;
      }, 0);

      state.weekPlans[dayIndex].nutrion_summary = state.weekPlans[
        dayIndex
      ].meals.reduce(
        (prevSum, { nutrion }) => {
          return {
            carbohydrates: prevSum.carbohydrates + nutrion.carbohydrates * 1,
            fats: prevSum.fats + nutrion.fats * 1,
            protein: prevSum.protein + nutrion.protein * 1,
          };
        },
        {
          carbohydrates: 0,
          fats: 0,
          protein: 0,
        }
      );
    },
    setNutritionistNote(state, nutritionistNote) {
      state.nutritionistNote = nutritionistNote;
    },
    setTrainingNote(state, trainingNote) {
      state.trainingNote = trainingNote;
    },
    setIsLoading(state, isLoading) {
      state.isLoading = isLoading;
    },
    setIsUpdatingShoppingList(state, isUpdatingShoppingList) {
      state.isUpdatingShoppingList = isUpdatingShoppingList;
    },
    setShouldUpdateShoppingList(state, shouldUpdateShoppingList) {
      state.shouldUpdateShoppingList = shouldUpdateShoppingList;
    },
    setErrors(state, errors) {
      state.errors = errors;
    },
    clearErrorsFor(state, errorKey) {
      return deleteNestedKeyAndClearEmpty(state.errors, errorKey.split("."));
    },
  },
  actions: {
    updateWeekPlanShoppingListLoading(
      { state, dispatch, commit },
      { weekPlanId, status }
    ) {
      if (state.planId * 1 === weekPlanId) {
        if (status === "success") {
          dispatch("fetchDietaryPlanShoppingList", state.planId);
        } else {
          notify({
            type: "danger",
            text: "Shopping list update failed",
            duration: 5000,
          });

          commit("setIsUpdatingShoppingList", false);
        }
      } else {
        notify({
          type: status === "success" ? "success" : "danger",
          text:
            status === "success"
              ? "Shopping list successfully updated"
              : "Shopping list update failed",
          duration: 5000,
          pauseOnHover: true,
          data: {
            buttonText: "Go to",
            buttonCallback: () =>
              router.push({
                name: "PrepareDietaryPlan",
                params: { id: weekPlanId },
              }),
          },
        });
      }
    },
    async updateShoppingList({ commit, state }) {
      if (!state.isUpdatingShoppingList) {
        commit("setIsUpdatingShoppingList", true);
        await http.put(
          `dietitian/dietary-week-plan/${state.planId}/shopping-list`
        );
      }
    },
    async fetchDietaryPlanShoppingList({ commit }, id) {
      const { data } = await http.get(
        `dietitian/dietary-week-plan/${id}/shopping-list`
      );

      commit("setShoppingList", data);

      commit("setShouldUpdateShoppingList", false);
      commit("setIsUpdatingShoppingList", false);

      notify({
        type: "success",
        text: "Shopping list successfully updated",
        duration: 5000,
      });
    },
    async fetchPrescription({ commit }, id) {
      commit("setIsLoading", true);

      const { data } = await http.get(`pharmacist/prescriptions/${id}`);

      commit("setPatientData", data.patient);
      commit("setSelectedPlan", data.selected_plan);
      commit("setDoctorDescription", data.doctor_description);
      commit("setTestResults", data.test_results);
      commit("setHealthMetrics", data.health_metrics);
      commit("setGeneralSurvey", data.general_survey);
      commit("setWeightLossSurvey", data.weight_loss_survey);

      commit("setIsLoading", false);
    },
    async fetchDietaryPlan({ commit }, id) {
      commit("setIsLoading", true);
      commit("setPlanId", id);

      const [{ data: dataWeekPlan }, { data: dataWeekPlanDays }] =
        await Promise.all([
          http.get(`dietitian/dietary-week-plan/${id}`),
          http.get(`dietitian/dietary-week-plan/${id}/days`),
        ]);

      commit("setPatientData", dataWeekPlan.patient);
      commit("setSelectedPlan", dataWeekPlan.selected_plan);
      commit("setDoctorDescription", dataWeekPlan.doctor_description);
      commit("setTestResults", dataWeekPlan.test_results);
      commit("setHealthMetrics", dataWeekPlan.health_metrics);
      commit("setGeneralSurvey", dataWeekPlan.general_survey);
      commit("setWeightLossSurvey", dataWeekPlan.weight_loss_survey);
      commit(
        "setNutritionistNote",
        dataWeekPlan.descriptions.nutritionist_note
      );
      commit("setTrainingNote", dataWeekPlan.descriptions.training_note);
      commit("setWeekPlans", dataWeekPlanDays.plans);
      commit("setShoppingList", dataWeekPlanDays.shopping_list);
      commit("setWeekNumber", dataWeekPlanDays.week_number);

      commit(
        "setShouldUpdateShoppingList",
        dataWeekPlanDays.should_update_shopping_list
      );
      commit(
        "setIsUpdatingShoppingList",
        dataWeekPlanDays.is_updating_shopping_list
      );
      commit("setIsLoading", false);
    },
    async storePlan({ state }, isDone) {
      return http.put(`dietitian/dietary-week-plan/${state.planId}`, {
        week_plans: state.weekPlans,
        nutritionist_note: state.nutritionistNote,
        training_note: state.trainingNote,
        isDone,
      });
    },
    async getNextPlanId(_, { currentId, isPrevious }) {
      const { data } = await http.get(
        "dietitian/dietary-week-plan/next/" + currentId,
        {
          params: {
            is_previous: isPrevious,
          },
        }
      );
      return data;
    },
  },
};

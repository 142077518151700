<template>
  <Teleport to="body">
    <notifications
      :class="!isMobile ? 'mb-5 mr-6' : 'mt-1'"
      :position="isMobile ? 'top right' : 'bottom right'"
      :width="isMobile ? '100%' : '320px'"
    >
      <template #body="{ item, close }">
        <div
          :class="themes[item.type]"
          class="notification px-3 py-6 lg:py-4 my-1 rounded-2xl text-white bg-opacity-90 mx-2 lg:mx-0"
        >
          <div class="flex justify-between items-center space-x-3">
            <span class="mr-auto leading-tight">{{ $t(item.text) }}</span>
            <button
              class="focus:outline-none inline-flex flex-shrink-0"
              @click="close"
            >
              <CoreButtonBase
                v-if="item.data?.buttonText"
                theme="white"
                @click="item.data?.buttonCallback()"
                >{{ item.data.buttonText }}
              </CoreButtonBase>
              <img
                v-else
                alt=""
                height="28"
                loading="lazy"
                src="@/assets/images/base/icons/close-circle-white.svg"
                width="28"
              />
            </button>
          </div>
        </div>
      </template>
    </notifications>
  </Teleport>
</template>

<script>
import isMobile from "@/mixins/isMobile";
import CoreButtonBase from "@/components/Core/ButtonBase.vue";

export default {
  name: "LayoutsNotification",
  components: { CoreButtonBase },
  mixins: [isMobile],
  data() {
    return {
      themes: {
        info: "bg-fiolet",
        success: "bg-green",
        danger: "bg-red",
      },
    };
  },
};
</script>
